import "./different.css";

const Different = () => {
  return (
    <div className="section-container">
      <p className="main-service-header">What Makes Us Different?</p>
      <div className="section-card-container">
        <div className="section-card">
          <h3>Tailored Approach</h3>
          <p>
            We understand that every business is unique, and so are its needs.
            Our solutions are customized to fit your specific goals and
            challenges, ensuring maximum impact.
          </p>
        </div>
        <div className="section-card">
          <h3>Seamless Collaboration</h3>
          <p>
            We believe in a true partnership. Our team works hand-in-hand with
            you at every stage, fostering open communication and transparency to
            achieve the best results.
          </p>
        </div>
        <div className="section-card">
          <h3>Quality Without Compromise</h3>
          <p>
            Excellence is at the core of everything we do. From development to
            support, our focus on quality and attention to detail ensures your
            software performs flawlessly.
          </p>
        </div>
        <div className="section-card">
          <h3>Future-Ready Solutions</h3>
          <p>
            We anticipate tomorrow’s needs. Our solutions are designed to scale
            and evolve with your business, keeping you ahead of the curve.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Different;
