import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

interface ContactUsState {
    isContactUsModalOpen: boolean
}
const initialState: ContactUsState = {
  isContactUsModalOpen: false,
};


export const contactUsSlice = createSlice({
  name: 'contact us',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setIsContactUsModalOpen: (state: ContactUsState, action: PayloadAction<boolean>) => {
      state.isContactUsModalOpen = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: () => {
  },
});

export const { setIsContactUsModalOpen } = contactUsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`


const contactUsStateSelector = (state: any) => state.contactUsReducer;

export const isContactUsModalOpenSelector = createSelector(contactUsStateSelector, contactUs => contactUs.isContactUsModalOpen);



export default contactUsSlice.reducer;
