import useWindowSize from "../../hooks/useWindowSize";
import "../TechView/Techview.css";

const TechView = ({
  icon,
  title,
  ml,
}: {
  icon: React.ReactNode;
  title: string;
  ml?: number;
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 961;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: ml,
        justifyContent: "center",
      }}
    >
      <div className="tech-icon">{icon}</div>

      <p className="tech-title">{title}</p>
    </div>
  );
};
export default TechView;
