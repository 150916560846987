import "./mission.css";
import Lottie from "lottie-react";
import contactUsAnimation from "../../../assets/lottie/ContactUs.json";
import { MdDiamond, MdRemoveRedEye } from "react-icons/md";
import { GiCornerFlag } from "react-icons/gi";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import "intersection-observer";
import VisionMissionValues from "../../../assets/vision-mission-value.png";
import VisionImage from "../../assets/vision.png";
import ValuesImage from "../../assets/values.png";
import HexagonalCard from "../../../Components/Card/HexagonalCard/HexagonalCard";

const Mission = () => {
  const { width } = useWindowSize();
  const isMobile = width < 961;
  const [isVisible, setIsVisible] = useState(false);

  const [index, setIndex] = useState(0);
  const missionContainerRef = useRef<any>(null);

  useEffect(() => {
    const handleScroll = () => {
      const missionContainer = missionContainerRef.current;
      if (!missionContainer) {
        return;
      }

      const rect = missionContainer.getBoundingClientRect();

      const isCurrentlyVisible =
        rect.top < window.innerHeight && rect.bottom >= 10;
      setIsVisible(isCurrentlyVisible);
    };

    const handleFocus = () => {
      setIsVisible(true);
    };

    // Initial check
    handleScroll();

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Attach focus event listener
    const missionContainer = missionContainerRef.current;
    if (missionContainer) {
      missionContainer.addEventListener("focus", handleFocus);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (missionContainer) {
        missionContainer.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  return (
    <div className="mission-container">
      <p className="main-service-header">Our Core Beliefs</p>
      <img
        className={`values-img ${isVisible ? "fade-in active" : "fade-in"}`}
        ref={missionContainerRef}
        src={VisionMissionValues}
        alt="Mission Values Vision Principles"
      />
    </div>
  );
};

export default Mission;
