import "./About.css";
import { TiChevronRight } from "react-icons/Ti";
import { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngular } from "react-icons/fa";
import TechView from "../../Components/TechView/TechView";
import Journey from "../../assets/Journey.png";
import ProblemSolving from "../../assets/Problem solving.png";
import BackendSvg from "../../assets/SVG/Back-End-svg";
import MobileSvg from "../../assets/SVG/Mobile-svg";
import DatabaseSvg from "../../assets/SVG/Database-svg";
import CloudSvg from "../../assets/SVG/Cloud-svg";
import CmsSvg from "../../assets/SVG/Cms-svg";
import useWindowSize from "../../hooks/useWindowSize";
import Mission from "../Home/Mission";
import Testimonial from "../Testimonial";

const AboutUsScreen = () => {
  const [active, setActive] = useState<number>(0);

  const { width } = useWindowSize();
  const isMobile = width < 641;

  const [isVisible, setIsVisible] = useState(false);

  const aboutContainerRef = useRef<any>(null);

  useEffect(() => {
    const handleScroll = () => {
      const aboutContainer = aboutContainerRef.current;
      if (!aboutContainer) {
        return;
      }

      const rect = aboutContainer.getBoundingClientRect();

      const isCurrentlyVisible =
        rect.top < window.innerHeight && rect.bottom >= 10;
      setIsVisible(isCurrentlyVisible);
      setActive(0);
    };

    const handleFocus = () => {
      setIsVisible(true);
      setActive(0);
    };

    // Initial check
    handleScroll();

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Attach focus event listener
    const aboutContainer = aboutContainerRef.current;
    if (aboutContainer) {
      aboutContainer.addEventListener("focus", handleFocus);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (aboutContainer) {
        aboutContainer.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  return (
    <div className="about-main-container">
      <div className="about-header-container">
        <p className="main-service-header">
          <span className="quote-mark">“</span>Where Boundaries Disappear,
          Innovation Flourishes<span className="quote-mark">”</span>
        </p>
        <div className="divider"></div>
        <span className="technology-description">
          Infinitude Stack is where imagination meets innovation. We are a team
          of forward-thinkers dedicated to pushing the limits of technology and
          design, creating groundbreaking solutions tailored to meet the unique
          needs of our clients. Our approach goes beyond conventional; we focus
          on delivering software that transforms visions into reality, ensuring
          businesses stay ahead in a constantly evolving digital world. At
          Infinitude Stack, we don’t just follow trends — we set them.
        </span>
      </div>
      <div>
        <div className="service-section">
          <img src={Journey} alt={"Our Journey"} />

          <div className="service-description">
            <h3>A Path of Innovation and Growth</h3>
            <p>
              Our journey is fueled by a desire to create meaningful, lasting
              change in the digital world. We collaborate closely with our
              clients, understanding their unique needs, and turning challenges
              into opportunities. Every project we undertake reflects our
              dedication to excellence and our belief in the power of technology
              to shape the future.
            </p>
          </div>
        </div>
        <div className="service-section">
          {isMobile && <img src={ProblemSolving} alt={"Problem Solving"} />}
          <div className="service-description">
            <h3>Innovators Crafting Beyond Expectations</h3>
            <p>
              Infinitude Stack isn’t just a software solution company; we are a
              team of innovators, problem solvers, and visionaries dedicated to
              making a difference in every project we take on. Let us partner
              with you to create solutions that go beyond expectations.
            </p>
          </div>
          {!isMobile && <img src={ProblemSolving} alt={"Problem Solving"} />}
        </div>
      </div>
      <div className="mission-div">
        <Mission />
      </div>
      <Testimonial />
    </div>
  );
};

export default AboutUsScreen;
