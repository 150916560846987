// import React from "react";
// import "./Footer.css";
// import { FaFacebook, FaLinkedin } from "react-icons/fa";
import Logo from "../../assets/logo-without-name.png";
import Infinitude from "../../assets/infinitude.png";

// const Footer = () => {
//   return (
//     <div className="box">
//       <div className="container">
//         <div className="row">
//           <div>
//             <img
//               style={{
//                 width: 250,
//                 height: 250,
//               }}
//               src={Logo}
//               alt="InfinitudeStack"
//             />
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifySelf: "flex-end",
//           }}
//         >
//           <ul>
//             <li>
//               <a href="https://www.linkedin.com/company/infinitudestack/">
//                 <i>
//                   <FaLinkedin />
//                 </i>
//               </a>
//             </li>
//             <li>
//               <a href="https://www.facebook.com/">
//                 <i>
//                   <FaFacebook />
//                 </i>
//               </a>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Footer;

import React from "react";
import "./Footer.css";
import { FaInstagram, FaLinkedin, FaGoogle, FaFacebook } from "react-icons/fa";
import useWindowSize from "../../hooks/useWindowSize";
import { useNavigate } from "react-router";
import { setIsContactUsModalOpen } from "../../state/ContactUs/ContactUs.slice";
import { useDispatch } from "react-redux";
import { MdEmail, MdLocationPin, MdPhone } from "react-icons/md";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { width } = useWindowSize();
  const isMobile = width < 695;
  return (
    <footer>
      <div className="footer-content">
        {/* <h3>Food Junction</h3> */}
        <div className="brand-container">
          <div className="logo-container">
            <img className="logo-main" src={Logo} alt="InfinitudeStack" />
            <img
              className="infinitude"
              src={Infinitude}
              alt="InfinitudeStack"
            />
          </div>
          <ul className="socials">
            <li>
              <a href="https://www.linkedin.com/company/infinitudestack/">
                <FaLinkedin size={isMobile ? 20 : 25} color="#17565a" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/infinitudestack">
                <FaFacebook size={isMobile ? 20 : 25} color="#17565a" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/infinitudestack/">
                <FaInstagram size={isMobile ? 20 : 25} color="#17565a" />
              </a>
            </li>
          </ul>
          <button onClick={() => navigate("/ContactUs")}>CONTACT US</button>
        </div>
        <div className="footer-menu">
          <h4>Services</h4>
          <ul>
            <li onClick={() => navigate("/Services/#Web-Development")}>
              <p>Web Development</p>
            </li>
            <li onClick={() => navigate("/Services/#Mobile-App-Development")}>
              <p>Mobile App Development</p>
            </li>
            <li onClick={() => navigate("/Services/#Cloud-Services")}>
              <p>Cloud Services</p>
            </li>
            <li onClick={() => navigate("/Services/#UX-UI-Services")}>
              <p>UX/UI Services</p>
            </li>
            <li onClick={() => navigate("/Services/#QA-Testing")}>
              <p>QA & Testing</p>
            </li>
            <li onClick={() => navigate("/Services/#Support-Maintenance")}>
              <p>Support & Maintenance</p>
            </li>
          </ul>
        </div>

        {/* <div className="footer-menu">
          <h4>Quick Links</h4>
          <ul className="f-menu">
            <li onClick={() => navigate("/")}>
              <p>Home</p>
            </li>
            <li onClick={() => navigate("/#Services")}>
              <p>Services</p>
            </li>
            <li onClick={() => navigate("/#About")}>
              <p>About</p>
            </li>
            <li onClick={() => navigate("/#Testimonial")}>
              <p>Testimonial</p>
            </li>
            <li onClick={() => navigate("/#ContactUs")}>
              <p>Contact Us</p>
            </li>
          </ul>
        </div> */}

        <div className="footer-menu">
          <h4>Contacts</h4>
          <ul>
            <li>
              <a href="tel:+15195049076">
                <MdPhone
                  className={` ${isMobile && "my-1"} `}
                  size={isMobile ? 15 : 20}
                  style={{
                    marginRight: 10,
                  }}
                />
                <span>+1 (519) 504 - 9076</span>
              </a>
            </li>
            <li>
              <a href="mailto:contact@infinitudestack.com">
                <MdEmail
                  size={isMobile ? 15 : 20}
                  style={{
                    marginRight: 10,
                  }}
                />
                <span>info@infinitudestack.com</span>
              </a>
            </li>
            <li>
              <a href="geo:43° 27' 10.6884'' N , 80° 29' 42.2304'' W">
                <MdLocationPin
                  size={isMobile ? 15 : 23}
                  style={{
                    marginRight: 10,
                  }}
                />
                <span>Kitchener, ON, Canada</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          copyright &copy;{" "}
          <a href="/" style={{ textDecoration: "none", color: "#17565a" }}>
            Infinitude Stack Inc.
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
