import React, { useEffect, useState } from "react";
import "./header.css";
import { useLocation } from "react-router-dom";

interface HeaderButtonProps {
  title: string;
  onClick: (e: any) => void;
  id: string;
}

const HeaderButton: React.FC<HeaderButtonProps> = (props) => {
  const [hover, setHover] = useState(false);
  const location = useLocation();
  const [isActive, setIsActive] = useState(
    location.pathname === props.id
    //   ||
    // (location.pathname === "/ContactUs" &&
    //   location.hash === "" &&
    //   props.title === "Contact Us")
  );

  console.log(location.pathname.replace(/\/$/, ""), props.id);

  useEffect(() => {
    if (location.pathname === "/") {
      setIsActive(location.pathname === props.id);
    } else {
      setIsActive(location.pathname.replace(/\/$/, "") === props.id);
    }
    // setIsActive(
    //   location.hash.replace("#", "") === `${props.title.replace(" ", "")}` ||
    //     (location.pathname === "/" &&
    //       location.hash === "" &&
    //       props.title === "Home")
    //   //   ||
    //   // (location.pathname === "/ContactUs" &&
    //   //   location.hash === "" &&
    //   //   props.title === "Contact Us")
    // );
  }, [location]);

  // useEffect(() => {
  //   const sections = document.querySelectorAll("div[id]");
  //   window.addEventListener("scroll", () => {
  //     let scrollY = window.pageYOffset;
  //     let sectionId = [];

  //     sections.forEach((current: any) => {
  //       const sectionHeight = current.offsetHeight;
  //       const sectionTop = current.offsetTop - 80;
  //       sectionId = current.getAttribute("id");

  //       if (
  //         scrollY > sectionTop &&
  //         scrollY <= sectionTop + sectionHeight &&
  //         sectionId !== "root"
  //       ) {
  //         setIsActive(
  //           props.title.replace(" ", "") === sectionId ||
  //             (sectionId === "root" &&
  //               location.pathname === "/" &&
  //               location.hash === "" &&
  //               props.title === "Home")
  //         );

  //         // window.history.replaceState(null, "", `#${sectionId}`);
  //       }
  //     });
  //   });
  // }, []);

  return (
    <button
      {...props}
      className="header-buttons"
      style={{
        // color: isActive || hover ? "#51518f" : "#6677a8",
        fontWeight: isActive || hover ? "bolder" : 800,
        transition: "color 0.4s",
      }}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
    >
      {props.title.toUpperCase()}
      {(isActive || hover) && <div className="header-button-line"></div>}
    </button>
  );
};

export default HeaderButton;
