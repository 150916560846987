import "./testimonial.css";
import Trvlar from "../../assets/trvlar.png";
import Vera from "../../assets/Vera_logo.webp";
const Testimonial = () => {
  return (
    <div className="testimonial">
      <p className="main-service-header">
        Voices of Trust:
        <br />
        Stories from Our Clients!
      </p>
      <div className="testimonial-container">
        <div className="testimonial-card">
          <div className="testimonial-logo">
            <img src={Trvlar} alt={`Trvlar logo`} />
          </div>
          <div className="testimonial-content">
            <h3 className="testimonial-company-name">Trvlar</h3>
            <p className="testimonial-story">
              Trvlar had the pleasure of working with Infinitude Stack for the
              past years in mobile app and web development capacities. Infintude
              has an outstanding software developers who truly excelled in every
              aspect. Their attention to details and app development skills are
              top-notch, crafting a user-friendly interface embedded with
              seamless functionality. What truly sets Infintude Stack apart is
              their remarkable project management skills that complements their
              software development acumen. They were able to keep the team on
              track, communicated progress effectively, and handled challenges
              with ease. Beyond their technical prowess, Infinitude Stack is a
              delight to work with approachable and open to ideas.
            </p>
          </div>
        </div>
        <div className="testimonial-card">
          <div className="testimonial-logo">
            <img src={Vera} alt={`Vera logo`} />
          </div>
          <div className="testimonial-content">
            <h3 className="testimonial-company-name">Vera InkJet</h3>
            <p className="testimonial-story">
              Excellent service at a fair price. Would recommend.
            </p>
          </div>
        </div>
      </div>
    </div>
    // <div className="testimonial">
    //   <p className="main-service-header">
    //     Voices of Trust:
    //     <br />
    //     Stories from Our Clients!
    //   </p>
    //   <div className="testimonial-container">
    //     {" "}
    //     <figure className="figure">
    //       <blockquote>
    // Trvlar had the pleasure of working with Infinitude Stack for the
    // past years in mobile app and web development capacities. Infintude
    // has an outstanding software developers who truly excelled in every
    // aspect. Their attention to details and app development skills are
    // top-notch, crafting a user-friendly interface embedded with seamless
    // functionality. What truly sets Infintude Stack apart is their
    // remarkable project management skills that complements their software
    // development acumen. They were able to keep the team on track,
    // communicated progress effectively, and handled challenges with ease.
    // Beyond their technical prowess, Infinitude Stack is a delight to
    // work with approachable and open to ideas.
    //         <div className="arrow"></div>
    //       </blockquote>
    //       <div className="testimonial-name">
    //         <img src={Trvlar} alt="trvlar" />
    //         <div className="author">
    //           <h5>Trvlar</h5>
    //         </div>
    //       </div>
    //     </figure>
    //     <figure className="figure">
    //       <blockquote>
    //         Excellent service at a fair price. Would recommend.
    //         <div className="arrow"></div>
    //       </blockquote>
    //       <div className="testimonial-name">
    //         <img src={Vera} alt="trvlar" />
    //         <div className="author">
    //           <h5>Vera InkJet</h5>
    //         </div>
    //       </div>
    //     </figure>
    //   </div>
    // </div>
  );
};

export default Testimonial;
