import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import "./home.css";
import backgroundImage from "../../assets/code-background.jpg";
import { Link, NavLink } from "react-router-dom";
import ServicesScreen from "../Services";
import AboutUsScreen from "../About";
import ContactUsScreen from "../Contact";
import Lottie from "lottie-react";
import step from "../../assets/lottie/step-In.json";
import logo from "../../assets/logo-without-name.png";
import infinitudeHome from "../../assets/lottie/infinitude-home.json";
import useWindowSize from "../../hooks/useWindowSize";
import Modal from "react-modal";
import Mission from "./Mission";
import { useDispatch, useSelector } from "react-redux";
import {
  isContactUsModalOpenSelector,
  setIsContactUsModalOpen,
} from "../../state/ContactUs/ContactUs.slice";
import Testimonial from "../Testimonial";
import Info from "./info";
import Offer from "./Offer";
import Different from "./different";

const HomeScreen = () => {
  const [isVisible, setIsVisible] = useState(false);
  const homeContainerRef = useRef<any>(null);

  useEffect(() => {
    const handleScroll = () => {
      const homeContainer = homeContainerRef.current;
      if (!homeContainer) {
        return;
      }

      const rect = homeContainer.getBoundingClientRect();

      const isCurrentlyVisible =
        rect.top < window.innerHeight &&
        rect.bottom >= window.innerHeight / 400;
      setIsVisible(isCurrentlyVisible);
    };

    const handleFocus = () => {
      setIsVisible(true);
    };

    // Initial check
    handleScroll();

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Attach focus event listener
    const homeContainer = homeContainerRef.current;
    if (homeContainer) {
      homeContainer.addEventListener("focus", handleFocus);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (homeContainer) {
        homeContainer.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  return (
    <>
      <div id="Home">
        <div className="home">
          <div className="home-lottie-animation">
            <Lottie
              className="home-lottie-animation2"
              animationData={infinitudeHome}
              loop={false}
            />
          </div>
          {/* {width <= 961 && (
            <div className="home-lottie-animation-div">
              <Lottie
                className="home-lottie-animation2"
                animationData={infinitudeHome}
                loop={true}
              />
            </div>
          )} */}

          <div
            tabIndex={0} // Allow the div to receive focus
            ref={homeContainerRef}
            className={`home-title-div ${
              isVisible ? "home-title-div-visible" : ""
            }`}
            id="homeTitleDiv"
          >
            {/* <img
              src={logo}
              alt="Softwares"
              style={{
                position: "absolute",
                top: "20%",
                left: "12%",
                opacity: 0.1,
              }}
            /> */}
            {/* <p className="home-title">Infinitude Stack</p>
            <div className="divider"></div> */}
            <p className="home-description">
              Infinite software solutions under a single dome to meet all of
              your demands.
            </p>
          </div>

          {/* {width > 961 && (
            <div className="home-lottie-animation-div">
              <Lottie
                className="home-lottie-animation2"
                animationData={infinitudeHome}
                loop={true}
              />
            </div>
          )} */}
        </div>
        <Info />
      </div>

      {/* <div
        id="About"
        style={{
          marginTop: "4%",
          paddingTop: 80,
          height: "auto",
          backgroundColor: "#F0F1F6",
        }}
      >
        <AboutUsScreen />
      </div> */}

      <div
        id="Offer"
        style={{
          height: "auto",
          paddingTop: 55,
        }}
      >
        <Offer />
      </div>
      <div>
        <Different />
      </div>
      {/* <div className="more-info-div">
        <Lottie
          className="home-lottie-animation2"
          animationData={step}
          loop={true}
        />
        <div className="more-info-title-div">
          <p className="more-info-title">
            Enter in to the digital world to keep your ideas at everyone's
            finger tip!
          </p>
          {/* <br />
          <p className="sub-title">
            Your Industry needs at your fingertip.
          </p> */}
      {/* </div>
      </div> */}

      {/* 
      <div
        id="Services"
        style={{
          height: "auto",
          paddingTop: 55,
        }}
      >
        <ServicesScreen />
      </div>
      <div
        id="About"
        style={{
          marginTop: "4%",
          paddingTop: 80,
          height: "auto",
          backgroundColor: "#F0F1F6",
        }}
      >
        <AboutUsScreen />
      </div>
      <div id="Testimonial">
        <Testimonial />
      </div>
      <div
        id="ContactUs"
        style={{
          height: "auto",
          backgroundColor: "#F0F1F6",
        }}
      >
        <ContactUsScreen />
      </div> */}
      {/* <div>
        <Modal
          isOpen={isContactUsModalOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => dispatch(setIsContactUsModalOpen(false))}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <ContactUsScreen />
        </Modal>
      </div> */}
    </>
  );
};

export default HomeScreen;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: 0,
  },
};
