import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import {
  InlineWidget,
  PopupModal,
  useCalendlyEventListener,
} from "react-calendly";
import { MdEmail, MdLocationPin, MdPhone } from "react-icons/md";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import Lottie from "lottie-react";
import contactUsAnimation from "../../assets/lottie/ContactUs.json";
import "./contact.css";
import useWindowSize from "../../hooks/useWindowSize";
import { FaInstagram, FaLinkedin, FaGoogle, FaFacebook } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setIsContactUsModalOpen } from "../../state/ContactUs/ContactUs.slice";
import axios from "axios";

const ContactUsScreen = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");

  const isMobile = width <= 961;

  const resetForm = () => {
    setEmail("");
    setName("");
    setPhone("");
    setMessage("");
  };

  const sendMessage = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}`,
        {
          email: email,
          phone: phone,
          name: name,
          message: message,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setSuccess(
          "Successfully sent the message. Someone from our team will reach out to you."
        );
        setIsLoading(false);
        resetForm();
      })
      .catch((e) => {
        setError("Something went wrong! Please try again after sometime.");
        setIsLoading(false);
        resetForm();
      });
  };

  // return (
  //   <div className="contact-us">
  //     <p className="contact-us-title">
  //       Get More Insight or Want to Build - Reach Out to Us
  //     </p>
  //     <div className="contact-us-content">
  //       <Lottie
  //         className="contact-us-lottie"
  //         animationData={contactUsAnimation}
  //         loop={true}
  //       />
  //       <div className="contact-us-content-info">
  //         <div>
  //           <a
  //             href="mailto:infinitudestack@gmail.com"
  //             className="contact-us-link"
  //           >
  //             <MdEmail
  //               size={30}
  //               style={{
  //                 marginRight: isMobile ? 10 : 0,
  //               }}
  //             />
  //             <p>
  //               <b>infinitudestack@gmail.com</b>
  //             </p>
  //           </a>
  //         </div>
  //         <div>
  //           <a href="tel:+10000000000" className="contact-us-link">
  //             <MdPhone
  //               size={30}
  //               style={{
  //                 marginRight: isMobile ? 10 : 0,
  //               }}
  //             />
  //             <p>
  //               <b>+1 (000) 000-0000</b>
  //             </p>
  //           </a>
  //         </div>
  //         <div className="contact-us-link">
  //           <BsFillCalendarCheckFill
  //             size={26}
  //             onClick={() => setIsOpen(true)}
  //             style={{
  //               cursor: "pointer",
  //               marginRight: isMobile ? 10 : 0,
  //             }}
  //           />
  //           <p
  //             style={{
  //               cursor: "pointer",
  //             }}
  //             onClick={() => setIsOpen(true)}
  //           >
  //             <b>
  //               <u>Schedule Meeting</u>
  //             </b>
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //     <PopupModal
  //       onModalClose={() => setIsOpen(false)}
  //       open={isOpen}
  //       /*
  //        * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
  //        * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
  //        */
  //       rootElement={document.getElementById("root") as HTMLElement}
  //       url="https://calendly.com/infinitudestack/30min?hide_event_type_details=1&hide_landing_page_details=1&hide_gdpr_banner=1"
  //     />
  //   </div>
  // );
  //   return (
  //     <>
  //       <section>
  //         <div className="contact-us">
  //           {/* <div className="row"> */}
  //           <div>
  //             <div className="contact_inner">
  //               {/* <button
  //                   className="close-button"
  //                   onClick={() => dispatch(setIsContactUsModalOpen(false))}
  //                 >
  //                   X
  //                 </button> */}

  //               {/* <div className="row"> */}
  //               <div className="col">
  //                 <div className="contact_form_inner">
  //                   <p className="main-service-header">Contact Us</p>
  //                   <div className="contact_field">
  //                     <p>
  //                       Feel Free to contact us any time trough filling out and
  //                       submit the form below or provided contact info!
  //                     </p>
  //                     <div className="form__group field">
  //                       <input
  //                         type="input"
  //                         className="form__field"
  //                         // placeholder="Name"
  //                         name="name"
  //                         id="name"
  //                         required
  //                         value={name}
  //                         onChange={(e: any) => setName(e.target.value)}
  //                       />
  //                       <label className="form__label">Name</label>
  //                     </div>
  //                     <div className="form__group field">
  //                       <input
  //                         type="input"
  //                         className="form__field"
  //                         // placeholder="Email"
  //                         name="email"
  //                         id="email"
  //                         value={email}
  //                         required
  //                         onChange={(e: any) => setEmail(e.target.value)}
  //                       />
  //                       <label className="form__label">Email</label>
  //                     </div>
  //                     <div className="form__group field">
  //                       <input
  //                         type="input"
  //                         className="form__field"
  //                         // placeholder="Phone"
  //                         name="phone"
  //                         id="phone"
  //                         value={phone}
  //                         required
  //                         onChange={(e: any) => setPhone(e.target.value)}
  //                       />
  //                       <label className="form__label">Phone</label>
  //                     </div>
  //                     <div className="form__group field">
  //                       <textarea
  //                         className="form__field"
  //                         // placeholder="Message"
  //                         name="message"
  //                         id="message"
  //                         required
  //                         value={message}
  //                         onChange={(e: any) => setMessage(e.target.value)}
  //                       />
  //                       <label className="form__label">Message</label>
  //                     </div>
  //                     <button
  //                       className="contact_form_submit"
  //                       onClick={sendMessage}
  //                       disabled={
  //                         !name || !email || !phone || !message || isLoading
  //                       }
  //                     >
  //                       Send
  //                     </button>
  //                     {/* <InlineWidget
  //                           styles={{
  //                             height: "450px",
  //                             marginLeft: -25,
  //                             marginTop: -65,
  //                           }}
  //                           url="https://calendly.com/infinitudestack/30min?hide_event_type_details=1&hide_landing_page_details=1&hide_gdpr_banner=1"
  //                         /> */}
  //                   </div>
  //                 </div>
  //               </div>
  //               {/* <div className="col-md-2">
  //                     <div className="right_conatct_social_icon d-flex align-items-end">
  //                       <div className="socil_item_inner d-flex">
  //                         <li>
  //                           <a href="https://www.linkedin.com/company/infinitudestack/about">
  //                             <FaLinkedin
  //                               size={isMobile ? 20 : 30}
  //                               color="white"
  //                             />
  //                           </a>
  //                         </li>
  //                         <li>
  //                           <a href="https://www.facebook.com/infinitudestack">
  //                             <FaFacebook
  //                               size={isMobile ? 20 : 30}
  //                               color="white"
  //                             />
  //                           </a>
  //                         </li>
  //                         <li>
  //                           <a href="https://www.instagram.com/infinitudestack/">
  //                             <FaInstagram
  //                               size={isMobile ? 20 : 30}
  //                               color="white"
  //                             />
  //                           </a>
  //                         </li>
  //                       </div>
  //                     </div>
  //                   </div> */}
  //               {/* </div> */}
  //               {/* <div className="contact_info_sec">
  //                   <p>Contact Info</p>
  //                   <div>
  //                     <a
  //                       style={{
  //                         cursor: "pointer",
  //                         color: "white",
  //                         textDecoration: "none",
  //                       }}
  //                       href="tel:+15195049076"
  //                     >
  //                       <div
  //                         className={`d-flex ${
  //                           isMobile && "flex-column my-2"
  //                         } info_single align-items-center`}
  //                       >
  //                         <MdPhone
  //                           className={` ${isMobile && "my-1"} `}
  //                           size={isMobile ? 15 : 20}
  //                           style={{
  //                             marginRight: 10,
  //                           }}
  //                         />
  //                         <span>+1 (519) 504 - 9076</span>
  //                       </div>
  //                     </a>
  //                     <a
  //                       style={{
  //                         cursor: "pointer",
  //                         color: "white",
  //                         textDecoration: "none",
  //                       }}
  //                       href="mailto:info@infinitudestack.com"
  //                     >
  //                       <div
  //                         className={`d-flex ${
  //                           isMobile && "flex-column my-2"
  //                         } info_single align-items-center`}
  //                       >
  //                         <MdEmail
  //                           size={isMobile ? 15 : 20}
  //                           style={{
  //                             marginRight: 10,
  //                           }}
  //                         />
  //                         <span>info@infinitudestack.com</span>
  //                       </div>
  //                     </a>
  //                     <div
  //                       className={`d-flex ${
  //                         isMobile && "flex-column my-2"
  //                       } info_single align-items-center`}
  //                     >
  //                       <MdLocationPin
  //                         size={isMobile ? 15 : 23}
  //                         style={{
  //                           marginRight: 10,
  //                         }}
  //                       />
  //                       <span>Kitchener, ON, Canada</span>
  //                     </div>
  //                   </div>
  //                 </div> */}
  //             </div>
  //           </div>
  //           {/* </div> */}
  //         </div>
  //       </section>

  //       {/* <section className="map_sec">
  //         <div className="container">
  //           <div className="row">
  //             <div className="col-md-10 offset-md-1">
  //               <div className="map_inner">
  //                 <h4>Find Us on Google Map</h4>
  //                 <p>
  //                   Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  //                   Tempore quo beatae quasi assumenda, expedita aliquam minima
  //                   tenetur maiores neque incidunt repellat aut voluptas hic
  //                   dolorem sequi ab porro, quia error.
  //                 </p>
  //                 <div className="map_bind">
  //                   <iframe
  //                     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471220.5631094339!2d88.04952462217592!3d22.6757520733225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1596988408134!5m2!1sen!2sin"
  //                     width="100%"
  //                     height="450"
  //                     aria-hidden="false"
  //                   ></iframe>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </section> */}
  //     </>
  //   );
  return (
    <div
      className={`contact-main-container`}
      tabIndex={0} // Allow the div to receive focus
    >
      <div className="service-header-container">
        <div className="service-header-description">
          <h3>Let’s Connect: Your Next Big Idea Starts Here</h3>
          <div className="divider"></div>
          <p>
            Looking for Expert Software Development and Support? We’re just a
            message away! At Infinitude Stack, we value open communication and
            are committed to providing you with the best assistance. Whether
            you're looking for more information about our services, need
            technical support, or want to discuss a potential project, our team
            is ready to assist you. Reach out to us, and let’s start a
            conversation to explore how we can help you achieve your goals.
          </p>
          <div className="contact-form-card">
            <div className="contact_field">
              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  name="name"
                  id="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label className="form__label">Name</label>
              </div>
              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  name="email"
                  id="email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label className="form__label">Email</label>
              </div>
              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  name="phone"
                  id="phone"
                  value={phone}
                  required
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label className="form__label">Phone</label>
              </div>
              <div className="form__group field">
                <textarea
                  className="form__field"
                  name="message"
                  id="message"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <label className="form__label">Message</label>
              </div>
              <button
                className="contact_form_submit"
                onClick={sendMessage}
                disabled={!name || !email || !phone || !message || isLoading}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
