import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomeScreen from "./screens/Home";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import ContactUsScreen from "./screens/Contact";
import React, { useState } from "react";
import { Provider } from "react-redux";
import { store } from "./state/store";
import ServicesScreen from "./screens/Services";
import Technologies from "./screens/Technologies";
import AboutUsScreen from "./screens/About";

export const UserContext = React.createContext(null);

function App() {
  const [openContactUsModal, setUser] = useState(null);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <Header />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/Home" element={<Navigate replace to="/" />}></Route>
          <Route path="/Services" element={<ServicesScreen />}></Route>
          <Route path="/Technologies" element={<Technologies />}></Route>
          <Route path="/AboutUs" element={<AboutUsScreen />}></Route>
          <Route path="/ContactUs" element={<ContactUsScreen />}></Route>
        </Routes>
        <Footer />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
