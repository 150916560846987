import "./info.css";
import Costing from "../../../assets/costing.png";
import Timing from "../../../assets/timing.png";
import Innovation from "../../../assets/innovation.png";
import FastWorkaround from "../../../assets/fast-workaround.png";
import { useEffect, useRef, useState } from "react";

const Info = () => {
  const [isVisible, setIsVisible] = useState(false);
  const infoContainerRef = useRef<any>(null);

  useEffect(() => {
    const handleScroll = () => {
      const infoContainer = infoContainerRef.current;
      if (!infoContainer) {
        return;
      }

      const rect = infoContainer.getBoundingClientRect();

      const isCurrentlyVisible =
        rect.top < window.innerHeight &&
        rect.bottom >= window.innerHeight / 400;
      setIsVisible(isCurrentlyVisible);
    };

    const handleFocus = () => {
      setIsVisible(true);
    };

    // Initial check
    handleScroll();

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Attach focus event listener
    const infoContainer = infoContainerRef.current;
    if (infoContainer) {
      infoContainer.addEventListener("focus", handleFocus);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (infoContainer) {
        infoContainer.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  return (
    <div
      tabIndex={0} // Allow the div to receive focus
      ref={infoContainerRef}
      className={`info-containers ${
        isVisible ? "info-containers-visible" : ""
      }`}
      id="homeTitleDiv"
    >
      <div className="category-shape1">
        <img className="category-img" src={Costing} alt="Costing" />
        <h3 className="category-heading">
          Fixed Rates,
          <br />
          Unlimited Potential
        </h3>
        <div className="divider"></div>

        <p className="category-description">
          Achieve more with predictable pricing that maximizes your investment.
        </p>
      </div>

      <div className="category-shape2">
        <img className="category-img" src={Timing} alt="Timing" />
        <h3 className="category-heading">
          Precision in Planning,
          <br />
          Excellence in Execution
        </h3>
        <div className="divider"></div>
        <p className="category-description">
          Meticulous planning ensures flawless
          <br />
          execution.
        </p>
      </div>
      <div className="category-shape2">
        <img
          className="category-img"
          src={FastWorkaround}
          alt="FastWorkaround"
        />
        <h3 className="category-heading">
          Quick Execution,
          <br />
          No Compromise
        </h3>
        <div className="divider"></div>
        <p className="category-description">
          Efficient completion with unwavering quality and accuracy.
        </p>
      </div>
      <div className="category-shape3">
        <img className="innovation-img" src={Innovation} alt="Innovation" />
        <h3 className="category-heading">
          Innovative Solutions,
          <br />
          Tailored for You
        </h3>
        <div className="divider"></div>
        <p className="category-description">
          Leading-edge concepts designed to tackle your unique needs.
        </p>
      </div>
    </div>
  );
};

export default Info;
