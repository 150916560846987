import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./header.css";
import HeaderButton from "./HeaderButton";
import Logo from "../../assets/logo-without-name.png";
import Infinitude from "../../assets/infinitude.png";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { CgMenu } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { setIsContactUsModalOpen } from "../../state/ContactUs/ContactUs.slice";

const Header = () => {
  const navigate = useNavigate();
  const { pathname, hash, key } = useLocation();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("scroll", () => {
      setIsPopoverOpen(false);
    });
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 961;

  const buttons = [
    { key: "/", title: "Home" },
    { key: "/Services", title: "Services" },
    { key: "/Technologies", title: "Technologies" },
    { key: "/AboutUs", title: "About Us" },
    { key: "/ContactUs", title: "Contact Us" },
  ];

  const handleOnClick = (key: string) => {
    dispatch(setIsContactUsModalOpen(false));

    return navigate(key);
    // return navigate(
    //   index === 0 ? "/" : `/#${buttons[index].title.replace(" ", "")}`
    // );
    // index === 3
    //   ? dispatch(setIsContactUsModalOpen(true))
    //   :
    //
  };

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <nav>
      <div className="header-container">
        <div className="logo-container">
          <img className="logo-main" src={Logo} alt="InfinitudeStack" />
          <img className="infinitude" src={Infinitude} alt="InfinitudeStack" />
        </div>
        {!isMobile ? (
          <div className="div-header">
            {buttons.map(({ title, key }) => (
              <HeaderButton
                title={title}
                onClick={() => handleOnClick(key)}
                key={key}
                id={key}
              />
            ))}
          </div>
        ) : (
          <Popover
            isOpen={isPopoverOpen}
            positions={["bottom"]}
            padding={25}
            onClickOutside={() => setIsPopoverOpen(false)}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor={"white"}
                arrowSize={10}
                className="popover-arrow-container"
                arrowStyle={{
                  left: 160,
                }}
              >
                <div className="popover-content">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {buttons.map(({ title, key }) => (
                      <HeaderButton
                        title={title}
                        onClick={() => handleOnClick(key)}
                        id={key}
                        key={key}
                      />
                    ))}
                  </div>
                </div>
              </ArrowContainer>
            )}
          >
            <button
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              className="menu-button"
            >
              <CgMenu size={25} color="#17565a" />
            </button>
          </Popover>
        )}
      </div>
      {/* <div className="div-header"> */}
      {/* <div className="logo-container"> */}
      {/* <img
            style={{
              width: 140,
            }}
            src={Logo}
            alt="InfinitudeStack"
          /> */}
      {/* <h3
            style={{
              marginLeft: 500,
              color: "black",
            }}
          >
            SoluSoft
          </h3> */}
      {/* </div> */}

      {/* {!isMobile ? (
          <div>
            {buttons.map((button, index) => (
              <HeaderButton
                title={button.title}
                onClick={() => handleOnClick(index)}
                key={index}
              />
            ))}
          </div>
        ) : (
          <Popover
            isOpen={isPopoverOpen}
            positions={["bottom"]}
            padding={25}
            onClickOutside={() => setIsPopoverOpen(false)}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor={"white"}
                arrowSize={10}
                className="popover-arrow-container"
                style={{
                  paddingRight: 20,
                }}
                // arrowClassName="popover-arrow"
                arrowStyle={{
                  left: 160,
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: 10,
                    borderRadius: 5,
                    boxShadow: "0 1px 4px 0 gray",
                  }}
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {buttons.map((button, index) => (
                      <HeaderButton
                        title={button.title}
                        onClick={() => handleOnClick(index)}
                      />
                    ))}
                  </div>
                </div>
              </ArrowContainer>
            )}
          >
            <button
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              style={{
                padding: 0,
                backgroundColor: "transparent",
                border: 0,
                paddingRight: 30,
              }}
            >
              <CgMenu size={25} color="#6677a8" />
            </button>
          </Popover>
        )} */}
      {/* </div> */}
    </nav>
  );
};

export default Header;
